import React from 'react';
// import { Link } from 'gatsby';
import { Container } from './nav.css';
import { FaLinkedin, FaRegEnvelope } from 'react-icons/fa';
import { GoMarkGithub } from 'react-icons/go';

const Nav = () => (
  <Container>
    <ul>
      <li>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://linkedin.com/in/dan-melancon-5161b354"
        >
          <FaLinkedin />
        </a>
      </li>
      <li>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://github.com/dmelancon"
        >
          <GoMarkGithub />
        </a>
      </li>
      {/* <li>
          <a rel="noopener noreferrer" target="_blank" href="/DMELANCON_resume.pdf"><FaRegFileAlt/></a>
      </li> */}
      <li>
        <a href="mailto:mrdmelancon@gmail.com">
          <FaRegEnvelope />
        </a>
      </li>
    </ul>
  </Container>
);

export default Nav;
