import styled from 'styled-components';

export const Container = styled.div`
	 h2:hover {
	  cursor: help;
	 }
`;

export const Reveal = styled.div`
  height: ${({ isVisible }) => (isVisible ? '100%': '0px')};

  pointer-events: ${({ isVisible }) => (isVisible ? 'all': 'none')};
  max-height: ${({ isVisible }) => (isVisible ? '2000px': '0px')};
  opacity: ${({ isVisible }) => (isVisible ? '1.0': '0.0')};
  transition:max-height .4s, height .4s, opacity .4s;
`;