import styled from 'styled-components';

export const FormHolder = styled.form`
	{
	  padding: 0 2rem 4rem 0rem;
	  margin: 2rem 0;
	}
`;

export const FormInstructions = styled.p`
`;

export const FormLabel = styled.label`
	{
	  display: block;
	  font-size: 67.5%;
	  letter-spacing: 0.125em;
	  text-transform: uppercase;
	}
`;

export const FormInput = styled.input`
	{
	  display: block;
	  // font-size: 1.2rem;
	  font-size:16px;
	  padding: 1rem;
	}
	input[type='text'],
	input[type='number'],
	textarea {
	  font-size: 16px;
	}
`;
export const FormButton = styled.input`
	&{
		-webkit-appearance: none;
		background-color:#ffffff;
		border-radius:0px;
		border: 1px solid black;
		display:inline-block;
		cursor:help;
		color:#666666;
		// font-size:1em;
		padding:6px 24px;
		font-size: 1em;
	    font-weight: bold;
		margin-left:1em;
		position:relative;
	    box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.18);
	    z-index:0;
	}
	&:hover {
		background-color:#f6f6f6;
	}
	&:active,&:focus {
		outline-width:0px;
		// position:relative;
		// top:1px;
		out
	}
`;
