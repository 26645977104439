import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Item from 'components/gallery/item';
import { Container } from './gallery.css';



class Gallery extends Component {
	constructor(props){
		super(props);
		this.state = {
			activeItem : ''
		};
		this.handleItemOpen = this.handleItemOpen.bind(this);
	}
	
	handleItemOpen(item){
		if(item === this.state.activeItem){
			this.setState({
				activeItem:''
			});
		}else{
			this.setState({
				activeItem:item
			});
		}
	}

	render(){
		return(
			<Container>
				{this.props.items.map((item,i) => (
					<Item {...item} key={i} activeItem={this.state.activeItem}  handlePress={this.handleItemOpen}/>
				))}
			</Container>
		);
	}
}


Gallery.propTypes = {
items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Gallery;
