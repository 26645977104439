import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const BodyHolder = styled.div`
  {
    padding-top: 1em;
    margin-left: auto;
    margin-right: auto;
  }
  ${MEDIA.MIN_PHONE`
      padding-top:100px;
      max-width:550px;
      // padding-left:10px;
      // padding-right:10px;
  `}; 
  ${MEDIA.MIN_TABLET`
    padding-top:100px;
      max-width:600px;
      // padding-left:10px;
      // padding-right:10px;
      // background-color:red;

  `};
   ${MEDIA.MIN_DESKTOP`
    padding-top:100px;
      max-width:900px;
      // padding-left:10px;
      // padding-right:10px;
      // background-color:blue;
  `};
`;
