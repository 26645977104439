import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Gallery from 'components/gallery';
import ExpandableBox from 'components/expandableBox';
import Login from 'components/login';
import { graphql } from 'gatsby';
// import BREAKPOINTS from 'constants/breakpoints';

// import ThreeCanvas from 'components/ThreeCanvas';

const Index = ({ data }) => {
  const titleStyle = {
    // background: '#fff',
    // border: '1px solid black',
    // padding: '2px 4px',
    // margin: '-4px -5px',
    // boxShadow: '8px 8px 0 rgba(0, 0, 0, 0.18)'
  };

  return (
    <Layout>
      {/* <ThreeCanvas/> */}
      <Box>
        <Title as="h3" size="large">
          <span style={titleStyle}>{data.homeJson.title}</span>
          {
            // <div style = {titleStyle}dangerouslySetInnerHTML={{
            //     __html : data.homeJson.content
            // }}/>}
          }
        </Title>
      </Box>
      <ExpandableBox
        expanded={true}
        title={data.aboutJson.title}
        html={data.aboutJson.content.childMarkdownRemark.html}
      />
      <ExpandableBox
        expanded={true}
        title={data.homeJson.project_content}
        style={{ paddingBottom: '7rem' }}
      >
        <Login>
          <Gallery items={data.homeJson.gallery} />
        </Login>
      </ExpandableBox>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    aboutJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      project_content
      gallery {
        title
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
            frontmatter {
              year
              location
              role
              technologies
            }
          }
        }
        images {
          image {
            publicURL
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
