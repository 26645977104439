import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  padding: 2rem 0rem;
  // margin: 2rem 0;
  display: block;
  ${MEDIA.TABLET`
    display: block;
    padding: 2rem 2rem;
  `};
  ${MEDIA.PHONE`
      padding: 2rem 0rem 4rem 0rem;
      margin: 0px -4px;
    `};
`;
