const isBrowser = (typeof window != 'undefined');

const getUser = () =>
  window.sessionStorage.gatsbyUser
    ? JSON.parse(window.sessionStorage.gatsbyUser)
    : {};

const setUser = user => {
  return (window.sessionStorage.gatsbyUser = JSON.stringify(user));
};

export const handleLogin = ({ password }) => {
  if (!isBrowser) return;

  if (password === 'alright') {
    console.log('Credentials match! Setting the active user.');
    return setUser({
      name: 'Authed User'
    });
  }

  return false;
};

export const isLoggedIn = () => {
  if (!isBrowser) return;

  const user = getUser();

  return user ? user.name : null;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = callback => {
  if (!isBrowser) return;

  console.log('Ensuring the `gatsbyUser` property exists.');
  setUser({});
  callback();
};
