import React from 'react';
import PropTypes from 'prop-types';
import {FaRegEnvelope} from 'react-icons/fa';
import {FormHolder, FormInstructions, FormLabel, FormInput, FormButton } from './form.css';

// const titleStyle = {
//     background: '#fff',
//     border: '1px solid black',
//     padding: '2px 4px',
//     margin: '-4px -5px',
//     boxShadow: '8px 8px 0 rgba(0, 0, 0, 0.18)'
//   };

const Form = ({ handleSubmit, handleUpdate}) => (
  <FormHolder
    method="post"
    onSubmit={event => {
      handleSubmit(event);
    }}
  >
    <FormInstructions>
      <a href="mailto:mrdmelancon@gmail.com?Subject=Let%20me%20in!" style={{verticalAlign: 'middle'}}> <FaRegEnvelope/></a> for Access
    </FormInstructions>
    <span style={{display: 'inline-flex'}}><FormLabel>
      <FormInput
        type="password"
        name="password"
        onChange={handleUpdate}
      />
    </FormLabel>
    <FormButton  type="submit" value="&rarr;" /></span>
  </FormHolder>
);

Form.propTypes = {
  handleUpdate: PropTypes.func,
  handleSubmit: PropTypes.func
};
export default Form;
