import React, { Component } from 'react';
import Box from 'components/box';
import Title from 'components/title';
import PropTypes from 'prop-types';
// import Item from 'components/gallery/item';
import { Reveal, Container } from './expandableBox.css';

import { MdArrowDropDown,MdArrowDropUp } from 'react-icons/md';

class ExpandableBox extends Component {
  constructor(props){
    super(props);

    this.state = {
      expand : props.expanded
    };
    this.handleClick = this.handleClick.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.renderInner = this.renderInner.bind(this);
  }
  handleClick(){
    let expand = this.state.expand;

    this.setState({expand:!expand});
  }
  renderIcon(){
    return this.state.expand === true ? (<MdArrowDropUp  style={{verticalAlign: 'sub'}}/>) : (<MdArrowDropDown  style={{verticalAlign: 'sub'}}/>);
  }
  renderInner(){
    if (this.props.html){
      return( <Title as="h3" size="large">
              <div dangerouslySetInnerHTML={{
                  __html : this.props.html
              }} style={{paddingTop:'1em'}}/>
            </Title>);
    }else{
      return(null);
    }
  }
  render(){
    const {title,children} = this.props;
    const titleStyle = {
      background: '#fff',
      border: '1px solid black',
      padding: '4px 10px',
      margin: '-4px -5px',
      boxShadow: '6px 6px 0 rgba(0, 0, 0, 0.18)'
    };
    return(
      <Container>
        <Box>
          
          <Title  as="h2" size="large" onClick={this.handleClick}><span style={titleStyle}>{title}{this.renderIcon()}</span> </Title>
          <Reveal isVisible={this.state.expand} >
            {this.renderInner()}
            {children}
          </Reveal>
        </Box>
      </Container>
    )
  }
}

ExpandableBox.propTypes = {
  title: PropTypes.string.isRequired,
  html: PropTypes.string,
  children: PropTypes.node,
  expanded: PropTypes.bool.isRequired

};

export default ExpandableBox;


   