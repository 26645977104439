import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Press = styled.span`
  color: black;
    font-weight: 700;
    font-size: 1rem;
    margin: 2rem 0rem 1rem;
    text-transform: uppercase;
    -webkit-text-decoration: underline;
    text-decoration: underline;
`;

export const Figure = styled.figure`
  margin: 0rem -4px 0rem;
  padding:2rem 2rem 0rem;
  &.active {
    background: #fff;
   outline: 1px solid black;
    // padding: 2px 4px;
    // margin: -4px -5px;
    -webkit-box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.18);
    box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.18);
    margin-bottom:1em;

    padding:2rem;
  }
  // transition: box-shadow .4s, border .4s;
`;
export const Title = styled.span`
  display: block;
  font-size: 3rem;
  // font-weight: 700;
  margin: 0rem 0rem 1rem;
  // &:after{
  //   content: '↘';
  //   font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  //   white-space: no-wrap;
  // }
 ${MEDIA.PHONE`
    font-size: 2.2rem;
  `};
`;

export const Year = styled.p`
  font-size: 3rem;
  font-weight:700;
  margin: 1.1rem 0rem 1rem;
  padding-left:1rem;
  display:inline;
  // display:none;
`

export const Copy = styled.div`
  color: #000000;
  margin: 1rem 0rem 2rem;
  // font-size: 14px;
  ${MEDIA.TABLET`
    margin-bottom: 2rem;
  `};
 ${MEDIA.PHONE`
    font-size: 1.2rem;
    line-height:1.4rem;
    margin-bottom: 1rem;
  `};
  .press{
    color:black;
    font-weight:700;
    font-size: 1rem;
    margin:2rem 0rem 1rem;
    text-transform: uppercase;
    text-decoration:underline;
  }
  .description{
    margin:2rem 0rem 2rem;
    
  }
  .pressContent{
      color:#757575;
      font-size: 1.2rem;
  }
`;


export const LinkSty = styled.a`
    cursor: help;
    transition:color .3s;
    color:black;
    // &:hover{
    //   background: #fff;
    //   border: 1px solid black;
    //   padding: 2px 4px;
    //   margin: -4px -5px;
    //   -webkit-box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.18);
    //   box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.18);
    // }
    // .active &{
    //   background: #fff;
    //   border: 1px solid black;
    //   // padding: 2px 4px;
    //   margin: -4px -5px;
    //   -webkit-box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.18);
    //   box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.18);
    // }
    // transition: box-shadow .5s, border .4s;
`


export const ImageSty = styled.div`
  max-width: 100%;
  margin:2rem 0rem 0rem;
  &>img{
    width: 100%;
  }
`
export const Reveal = styled.div`
	pointer-events: ${({ isVisible }) => (isVisible ? 'all': 'none')};
  max-height: ${({ isVisible }) => (isVisible ? '20000px': '0px')};
  opacity: ${({ isVisible }) => (isVisible ? '1.0': '0.0')};
  transition: opacity .4s;
  overflow:hidden;
  // transition:${({ isVisible }) => (isVisible ?'max-height.2s, opacity .2s' : 'max-height 0s, opacity 0s;')};

`;