import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Img from 'gatsby-image';
import Image from 'gatsby-dynamic-image';

import {
  Press,
  Figure,
  Title,
  Copy,
  Reveal,
  ImageSty,
  LinkSty,
} from './item.css';
// import { Link } from 'gatsby';
import { FaCaretRight } from 'react-icons/fa';
import { MdArrowDropUp } from 'react-icons/md';

class Item extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   isActive : false
    // };
    // console.log(this.props.content);
    this.handleClick = this.handleClick.bind(this);
    this.curTarget = null;
  }

  handleClick(e) {
    // if (this.props.title !== this.props.activeItem){
    // this.curTarget = e.currentTarget;
    // this.props.handlePress(this.props.title);
    // },1000);
    // /setTimeout(()=>{
    // this.curTarget.scrollIntoView({ behavior: 'smooth',inline:'center'});
    // },450);
    // }else{
    e;
    this.props.handlePress(this.props.title);
    // document.documentElement.scrollTop = e.screenY;
    // }
  }

  render() {
    const { title, images, content } = this.props;
    const {
      year,
      location,
      technologies,
      role,
    } = content.childMarkdownRemark.frontmatter;
    const image = images && images.length ? images[0] : null;
    return (
      <Figure
        className={this.props.title === this.props.activeItem ? 'active' : ''}
      >
        <figcaption>
          <div>
            <Title
              className={
                this.props.title === this.props.activeItem ? 'active' : ''
              }
              onClick={this.handleClick}
            >
              <LinkSty>{title}</LinkSty>
            </Title>
          </div>
        </figcaption>
        <Copy>{role}</Copy>

        <Reveal
          isVisible={this.props.title === this.props.activeItem ? true : false}
        >
          {image && (
            <ImageSty>
              <Image node={image.image} alt={title} />
            </ImageSty>
          )}
          <Copy>
            <Press>
              {year}
              <FaCaretRight style={{ verticalAlign: 'bottom' }} />
              {location}
            </Press>
          </Copy>
          <Copy>
            <div
              dangerouslySetInnerHTML={{
                __html: content.childMarkdownRemark.html,
              }}
            />
          </Copy>
          <Press> Built With: </Press>
          <Copy>{technologies}</Copy>
          {images &&
            images
              .filter((i, k) => {
                return k > 0;
              })
              .map((i, k) => {
                return (
                  <ImageSty key={k}>
                    <Image node={i.image} alt={title + k} />
                  </ImageSty>
                );
              })}
          <div style={{ margin: '2rem 0rem 0rem' }}>
            <MdArrowDropUp
              style={{
                marginLeft: '50%',
                marginRight: '50%',
                marginTop: '0em',
                verticalAlign: 'sub',
                height: '3em',
                width: '3em',
                transform: 'translateX(-1.5em)',
              }}
              onClick={this.handleClick}
            />
          </div>
        </Reveal>
      </Figure>
    );
  }
}

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  year: PropTypes.string,
  content: PropTypes.object.isRequired,
  location: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  handlePress: PropTypes.func,
  activeItem: PropTypes.string,
};

export default Item;
