import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';


export const Parent = styled.div`
   position: fixed;
   bottom:0;
   left: 0;
   margin: 0;  
   width:100px;
   height:55px;  
    ${MEDIA.PHONE`
      padding-top: 1em;
      background-color:white;
       width:100%;
       z-index: 2;
    `};
`


export const Container = styled.header`
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    margin: 0;
    max-width: 20ch;
   
  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;
