import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from './form/form';
//import { Container } from './gallery.css';
import { handleLogin, isLoggedIn } from 'helpers/auth';

class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      password: '',
      loggedin:false
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    console.log(this.state);
    event.preventDefault();
    handleLogin(this.state);
    this.setState({
      loggedin:true
    });
  }

  render() {
    const { children } = this.props;
    if (isLoggedIn()) {
      return (children);
    }else{

      return (
          <Form
            handleUpdate={e => this.handleUpdate(e)}
            handleSubmit={e => this.handleSubmit(e)}
          />
      );
    }
  }
}

Login.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Login;

